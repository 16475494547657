import * as React from 'react';
import Styles from './testimonials.module.scss';

const testimonials = [
    {
        text: '„Herzlichen Dank für die Aufbruchstimmung“'
    },
    {
        text: '„Top praxistauglich“'
    },
    {
        text: '„Authentisch, menschlich, kompetent, sicher, souverän, der kann gut mit Menschen“'
    },
    {
        text: '„Es war alles hervorragend“'
    },
    {
        text: '„Ich bin richtig heiß, das jetzt sofort in Angriff zu nehmen“'
    },
    {
        text: '„Hat mich sehr motiviert, an diesem Thema zu arbeiten“'
    }
];

export default props => {
  return (
    <section className={`${Styles.section}`}>
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 col-lg-6">
                    {/* Contains the background image */}
                </div>
                <div className="col-12 col-lg-6 py-6 pl-lg-5 bg-blue color-white">
                    <h1 className="mb-5">Original-Kundenstimmen (Auszug)</h1>
                    <ul className={`list-unstyled`}>
                        {
                            testimonials.map((testimonial, index) => (
                                <li className="mb-4" key={index}>
                                    <blockquote>{testimonial.text}</blockquote>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </div>
    </section>
  );
}
