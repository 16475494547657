import * as React from 'react';
import Styles from './header.module.scss';
import Link from '../../global/link/link';
import Img from "gatsby-image/withIEPolyfill";
import { useStaticQuery, graphql } from 'gatsby';

export default props => {
  const imageQuery = useStaticQuery(graphql`
      query HeaderImage {
          file(name: {eq: "christoph-hauke"}) {
              childImageSharp {
                  fluid {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
      }
  `);

  return (
    <header className={`${Styles.header} d-md-flex`}>
      <div className={`${Styles.overlay} d-none d-md-block`}></div>

      <Img fluid={imageQuery.file.childImageSharp.fluid}
            objectFit="cover"
            objectPosition="50% 50%"
            alt="Bild von Christoph Hauke"
            className="d-md-none mb-5"></Img>
      <div className="container align-self-center">
        <div className="row">
          <div className="col-12 col-md-7 offset-md-5">
            <h1 className="mb-3">Professioneller Business-Moderator<br></br>für Klausurtagungen und Strategiemeetings</h1>
            <h1>Resultat:<br></br>Klare Ergebnisse und<br></br>starke Verbindlichkeit</h1>
            <div className="d-block my-5">
              <Link className="hauk-button" to="/#kontakt">Jetzt Business-Moderation anfragen</Link>
            </div>
            <Link className="unstyled" to="/#kennenlernen">Christoph Hauke kennenlernen</Link>
          </div>
        </div>
      </div>
    </header>
  );
}
