import { graphql, useStaticQuery } from 'gatsby';
import Img from "gatsby-image/withIEPolyfill";
import * as React from 'react';
import Link from '../../global/link/link';
import Styles from './meet-christoph-hauke.module.scss';

export default props => {
    const imageQuery = useStaticQuery(graphql`
        query MeetChristophHaukeImage {
            file(name: {eq: "christoph-hauke-2"}) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
        }
    `);
    return (
        <section className={`py-6 ${Styles.section}`} id="kennenlernen">
            <div className="container">
                <div className="row mb-4 mb-md-6">
                    <div className="col-12 text-center">
                        <h1>Christoph Hauke kennenlernen</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-4 mb-4 mb-md-0 order-md-2">
                    <Img fluid={imageQuery.file.childImageSharp.fluid}
                        objectFit="cover"
                        objectPosition="50% 50%"
                        alt="Bild von Christoph Hauke"></Img>
                    </div>
                    <div className="col-12 col-md-8">
                        <p>
                            Christoph Hauke ist Business-Experte, Moderator, Consultant und Speaker.
                        </p>
                        <p>
                            Als langjähriger Geschäftsführer und Aufsichtsratsvorsitzender hat er alle Höhen und Tiefen von Unternehmen selbst erlebt und viele Weiterentwicklungen in Bewegung gesetzt und erfolgreich realisiert.
                        </p>
                        <p>
                            Er ist vertraulicher Sparringspartner für Aufsichtsräte, Vorstände und Geschäftsleitungen und unterstützt bei der strategischen Unternehmensentwicklung und beim Business Development.
                        </p>
                        <p>
                            Christoph Hauke weiß aus eigenen Erfahrungen, wie Unternehmen und Geschäftsfelder zur Erreichung von mehr Wachstum, Effizienz und Profitabilität weiterentwickelt werden und wie Strategie-, Transformations- und Change-Projekte erfolgreich gelingen.
                        </p>
                        <p>
                            Mit seiner erfrischenden Moderation und seinen überzeugenden Fach-Impulsen mit vielen Best Practice- Beispielen sorgt er für klare Ergebnisse und ein verlässliches Commitment in Klausur-Tagungen und Strategie-Meetings.
                        </p>
                        <div className="d-block my-5">
                            <Link className="hauk-button" to="/#kontakt">Jetzt Business-Moderation anfragen</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
