import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from '../components/global/layout/layout';
import Header from '../components/index/header/header';
import Solutions from '../components/index/solutions/solutions';
import Process from '../components/index/process/process';
import Testimonials from '../components/index/testimonials/testimonials';
import TestimonialFullWidth from '../components/index/testimonial-full-width/testimonial-full-width';
import MeetChristophHauke from '../components/index/meet-christoph-hauke/meet-christoph-hauke';
import Form from '../components/index/form/form';
import Footer from '../components/global/footer/footer';
import Logos from '../components/index/logos/logos';

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
        description
        titleSuffix
        titleDivider
      }
    }
  }
`

export default props => {
  return (
    <Layout title={props.data.site.siteMetadata.title}
            description={props.data.site.siteMetadata.description}
            titleSuffix={props.data.site.siteMetadata.titleSuffix}
            titleDivider={props.data.site.siteMetadata.titleDivider}>
      <Header/>
      <Solutions/>
      <Process/>
      <Logos/>
      <Testimonials/>
      <TestimonialFullWidth/>
      <MeetChristophHauke/>
      <Form/>
      <Footer/>
    </Layout>
  );
}
