import * as React from 'react';
import Styles from './process.module.scss';
import Link from '../../global/link/link';

const steps = [
    {
        title: 'Ihre Anfrage übermitteln',
        text: 'Jetzt Business-Moderation anfragen'
    },
    {
        title: 'Details abstimmen',
        text: 'Ich melde mich schnellstmöglich bei Ihnen'
    },
    {
        title: 'Moderations-Bestätigung erhalten',
        text: 'Sie können Ihre Veranstaltung entspannt vorbereiten'
    }
];

export default props => {
  return (
    <section className="bg-blue color-white py-5">
        <div className="container my-5">
            <div className="row mb-8">
                <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 text-center">
                    <h1>Business-Moderation anfragen - So einfach geht’s</h1>
                </div>
            </div>
            <div className="row justify-content-center mb-4">
                {
                    steps.map((step, index) => (
                        <div className={`col-12 col-lg-4 mb-7 mb-lg-0 ${Styles.step}`} key={index}>
                            <span>{index + 1}</span>
                            <div className="d-flex">
                                <h2 className="mb-3 mt-auto">{step.title}</h2>
                            </div>
                            <p>{step.text}</p>
                        </div>
                    ))
                }
            </div>
            <div className="row">
                <div className="col text-center">
                    <div className="d-block my-5">
                        <Link className="hauk-button hauk-button-inverted" to="/#kontakt">Jetzt Business-Moderation anfragen</Link>
                    </div>
                    <Link className="unstyled" to="/#kontakt">Christoph Hauke kennenlernen</Link>
                </div>
            </div>
        </div>
    </section>
  );
}
