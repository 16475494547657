import { useStaticQuery, graphql } from 'gatsby';
import Img from "gatsby-image/withIEPolyfill";
import * as React from 'react';
import Styles from './testimonial-full-width.module.scss';

export default props => {
  const imageQuery = useStaticQuery(graphql`
      query Image {
          file(name: {eq: "christoph-hauke-square"}) {
              childImageSharp {
                  fluid {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
      }
  `);

  return (
    <section className={`container py-6 ${Styles.section}`}>
      <div className="row justify-content-center">
        <div className="col-12 col-md-4 mb-4 mb-md-0 order-md-2">
          <Img fluid={imageQuery.file.childImageSharp.fluid}
                objectFit="cover"
                objectPosition="50% 50%"
                alt=""
                className={`align-self-center ${Styles.img}`}
          ></Img>
        </div>
        <div className="col-12 col-md-8">
          <q className="color-blue d-block" cite="https://www.certo-gmbh.de/redaktion/2019/09/05/christoph-hauke-holt-sich-excellence-award-beim-speaker-slam-weltrekord/">
            Christoph Hauke ist einer<br></br>der besten seines Faches.
          </q>
          <small className="ml-auto"><span>PERSONAL</span><span>intern</span></small>
        </div>
      </div>
    </section>
  );
}
