import React, { useState, createRef } from 'react';
import Styles from './form.module.scss';

export default props => {
    const formRef = createRef()
    const [formValue, setFormValue] = useState({
        name: '',
        company: '',
        email: '',
        phone: '',
        date: '',
        location: '',
        message: ''
    });
    const [isLoading, setLoading] = useState(false);
    const [submitStatus, setSubmitStatus] = useState({
        customerMailSent: false,
        internalMailSent: false,
        initialState: true
    });

    const handleInputChange = event => {
        // Update the form state
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const newValue = { ...formValue };
        newValue[name] = value;
        setFormValue(newValue);
    };

    const submit = async event => {
        event.preventDefault();
        event.stopPropagation();
        formRef.current.classList.add('was-validated');

        if (formRef.current.checkValidity()) {
            // Lock the UI and set loading status
            setLoading(true);
            const allInputs = document.querySelectorAll('input,textarea,button');
            allInputs.forEach(element => element.disabled = true);

            // Prepare values
            const body = { ...formValue };
            for (const key of Object.keys(body)) {
                if (!body[key]) {
                    body[key] = 'Keine Angabe';
                }
            }

            const response = await fetch('/php/controllers/contact-form.controller.php', {
                method: 'POST',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });
            try {
                const jsonResponse = await response.json();
                setLoading(false);
                setSubmitStatus(jsonResponse);
            } catch (error) {
                if (window['Sentry']) {
                    window['Sentry'].captureException(error, {
                        extra: {
                            formValue
                        }
                    });
                }
                setLoading(false);
                setSubmitStatus({
                    customerMailSent: false,
                    internalMailSent: false
                });
            }
        }
    }

    const getFallbackMailto = () => {
        const to = 'info@christophhauke.de';
        const subject = 'Anfrage';
        const body = encodeURIComponent(JSON.stringify(formValue));
        return `mailto:${to}?subject=${subject}&body=${body}`;
    }

    return (
        <section className="container" id="kontakt">
            <div className="row justify-content-center">
                <div className="col-12 col-md-8 col-xl-6">
                    <form id="form" ref={formRef} noValidate className={`${Styles.form} needs-validation mb-5`} onSubmit={submit}>
                        <div className="d-flex flex-column mb-3">
                            <label htmlFor="form-name">Name</label>
                            <input type="text" required id="form-name" className="form-control" name="name" value={formValue.name.value} onChange={handleInputChange}></input>
                            <div className="invalid-feedback">
                                Bitte geben Sie Ihren Namen an.
                            </div>
                        </div>
                        <div className="d-flex flex-column mb-3">
                            <label htmlFor="form-company">Firma <span>optional</span></label>
                            <input type="text" id="form-company" className="form-control" name="company" value={formValue.company.value} onChange={handleInputChange}></input>
                        </div>
                        <div className="d-flex flex-column mb-3">
                            <label htmlFor="form-email">E-Mail</label>
                            <input type="email" required id="form-email" className="form-control" name="email" value={formValue.email.value} onChange={handleInputChange}></input>
                            <div className="invalid-feedback">
                                Bitte geben Sie Ihre E-Mail-Adresse an.
                            </div>
                        </div>
                        <div className="d-flex flex-column mb-3">
                            <label htmlFor="form-phone">Telefon</label>
                            <input type="tel" required id="form-phone" className="form-control" name="phone" value={formValue.phone.value} onChange={handleInputChange}></input>
                            <div className="invalid-feedback">
                                Bitte geben Sie Ihre Telefonnummer an.
                            </div>
                        </div>
                        <div className="d-flex flex-column mb-3">
                            <label htmlFor="form-date">Gewünschter Moderationstermin <span>optional</span></label>
                            <input type="date" id="form-date" className="form-control" name="date" value={formValue.date.value} onChange={handleInputChange}></input>
                        </div>
                        <div className="d-flex flex-column mb-3">
                            <label htmlFor="form-location">Gewünschter Veranstaltungsort <span>oder digitale Veranstaltung</span> <span>optional</span></label>
                            <input type="text" id="form-location" className="form-control" name="location" value={formValue.location.value} onChange={handleInputChange}></input>
                        </div>
                        <div className="d-flex flex-column mb-3">
                            <label htmlFor="form-message">Nachricht</label>
                            <textarea rows="5" required id="form-message" className="form-control" name="message" value={formValue.message.value} onChange={handleInputChange}></textarea>
                            <div className="invalid-feedback">
                                Bitte geben Sie eine Nachricht ein.
                            </div>
                        </div>
                        <div className="mb-3 custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" required id="form-consent"></input>
                            <label className={`custom-control-label ${Styles.defaultLabel}`} htmlFor="form-consent">
                                Ich akzeptiere die{' '}
                                <a href="/datenschutz" target="_blank" className="unstyled color-blue font-weight-bold">Datenschutzbestimmungen</a>{' '}
                                und bin damit einverstanden, per E-Mail
                                oder Telefon zu meiner Anfrage kontaktiert zu werden.
                            </label>
                            <div className="invalid-feedback">
                                Bitte akzeptieren Sie unsere Datenschutzbestimmungen.
                            </div>
                        </div>
                        <div className="d-flex flex-column">
                            <button className="hauk-button" type="submit">Jetzt Business-Moderation anfragen</button>
                        </div>
                    </form>

                    {
                        isLoading ? (
                            <div>
                                <p>
                                    Bitte warten, Ihre Anfrage wird gesendet.
                                </p>
                            </div>
                        ) : ''
                    }
                    {
                        (!isLoading && !submitStatus.initialState) ? (
                            <React.Fragment>
                                <div className={`${submitStatus.internalMailSent && submitStatus.customerMailSent ? 'd-block' : 'd-none'}`}>
                                    <h1>Vielen Dank</h1>
                                    <p>Ihre Anfrage wurde erfolgreich gesendet.</p>
                                </div>
                                <div className={`${!(submitStatus.internalMailSent && submitStatus.customerMailSent) ? 'd-block' : 'd-none'}`}>
                                    <h1>Es ist ein Fehler aufgetreten</h1>
                                    <div className={`${!submitStatus.customerMailSent && submitStatus.internalMailSent ? 'd-block' : 'd-none'}`}>
                                        <p>
                                            Wir haben Ihre Nachricht erhalten und melden uns bei Ihnen,
                                            allerdings konnte die Bestätigungsmail an Ihre E-Mail-Adresse
                                            {formValue.email} nicht gesendet werden.
                                        </p>
                                    </div>
                                    <div className={`${!submitStatus.internalMailSent ? 'd-block' : 'd-none'}`}>
                                        <p>
                                            Leider konnte Ihre Nachricht nicht übermittelt werden, wir haben
                                            Ihre Eingaben jedoch gespeichert. Bitte{' '}
                                            <a className="unstyled color-blue font-weight-bold" href={getFallbackMailto()}>nutzen Sie diesen Link</a>,
                                            um Ihre Anfrage direkt per E-Mail zu senden.
                                        </p>
                                    </div>
                                </div>
                            </React.Fragment>
                        ) : ''
                    }
                </div>
            </div>
        </section>
    );
}
