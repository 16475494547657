import { useStaticQuery, graphql } from 'gatsby';
import * as React from 'react';
import Img from "gatsby-image/withIEPolyfill";

export default props => {
    const logoQuery = useStaticQuery(graphql`
        query Logos {
            logos: allFile(filter: {dir: {regex: "/logos$/"}}) {
                nodes {
                    childImageSharp {
                        fixed(width: 90) {
                            ...GatsbyImageSharpFixed_withWebp_noBase64
                        }
                    }
                }
            }
        }
    `);

    return (
        <section className={`container py-6`}>
            <div className="row mb-6 justify-content-center">
                <div className="col-12 col-md-11 text-center">
                    <h1>
                        Folgende Unternehmen, Institutionen
                        <br className="d-none d-xl-inline"></br>
                        und Verbände vertrauten Christoph Hauke <br></br>(Auszug)
                    </h1>
                </div>
            </div>
            <div className="row">
                {
                    logoQuery.logos.nodes.map((logo, index) => (
                        <div key={index} className={`col-6 col-md-3 col-lg-2 mb-5 d-flex justify-content-center`}>
                            <Img fixed={logo.childImageSharp.fixed}
                                objectFit="cover"
                                objectPosition="50% 50%"
                                alt=""
                                className={`align-self-center`}
                            ></Img>
                        </div>
                    ))
                }
            </div>
        </section>
    );
}
