import * as React from 'react';
import Styles from './solutions.module.scss';

const solutions = [
    {
        icon: '/icons/conversation.svg',
        title: 'Moderation & Freiraum',
        text: 'Mehr persönlicher Mitwirkungs-Freiraum und Sicherheit in Struktur und Ablauf durch meine professionelle Moderation mit der Erfahrung von über 100 Klausur-Tagungen und Strategiemeeting für Unternehmen, Verbände und Institutionen.'
    },
    {
        icon: '/icons/inspiration.svg',
        title: 'Inspiration & Impulse',
        text: 'Bessere Orientierung und Strategie-Ausrichtung durch meinen fachlichen Input zu neuen Geschäftsmodellen, Business Development, digitale Transformation, moderne Führung, Employer Branding und weiteren Business-Themen.'
    },
    {
        icon: '/icons/heart.svg',
        title: 'Ergebnisse & Verbindlichkeit',
        text: 'Verlässliche Grundlage für Ihre weiteren Schritte durch klare Ergebnisse und ein starkes Commitment.'
    }
];

export default props => {
  return (
    <section className="container py-5 my-5">
        <div className="row mb-6">
            <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 text-center">
                <h1 className="font-weight-bold">
                    So wird Ihre Klausurtagung,
                    Ihr Führungskräfte-Forum
                    oder Ihr Strategiemeeting ein voller Erfolg!
                </h1>
            </div>
        </div>
        <div className="row justify-content-center">
            {
                solutions.map((solution, index) => (
                    <div className={`col-12 col-md-6 col-lg-4 mb-5 mb-lg-0 ${Styles.solution}`} key={index}>
                        <div className="mb-4">
                            <img src={solution.icon} alt=""></img>
                        </div>
                        <h2 className="mb-3">{solution.title}</h2>
                        <p>{solution.text}</p>
                    </div>
                ))
            }
        </div>
    </section>
  );
}
